var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fl-s-s warp w-1" }, [
    _c("div", { staticClass: "fr-s-c" }, [
      _c(
        "div",
        { staticClass: "choose-img fr-c relative" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary", loading: _vm.fileLoading } },
            [
              _c("img", {
                attrs: { src: require("../../../assets/img/uploadFile.png") },
              }),
              _vm._v("上传"),
            ]
          ),
          _c("input", {
            ref: "choose",
            staticClass: "img-view",
            staticStyle: { position: "absolute", opacity: "0" },
            attrs: {
              id: "choose",
              accept: _vm.accept,
              multiple: _vm.limit > 1,
              type: "file",
            },
            on: { change: _vm.changeFile },
          }),
        ],
        1
      ),
      _c("p", { staticClass: "memo" }, [_vm._v(_vm._s(_vm.uploadTips))]),
    ]),
    _c(
      "div",
      { staticClass: "w-1 fl-s-s" },
      _vm._l(_vm.fileArr, function (i, v) {
        return _c(
          "div",
          {
            key: v,
            staticClass: "fr-c w-1 m-t-10 file-item",
            style: {
              "background-color":
                _vm.disabled || _vm.limit > 1 ? "#F5F7FA" : "#fff",
            },
          },
          [
            _vm.disabled || _vm.limit > 1
              ? _c("img", {
                  staticClass: "file-icon",
                  attrs: { src: require("../../../assets/img/fileIcon.png") },
                })
              : _vm._e(),
            _c("el-input", {
              staticClass: "flex-1",
              attrs: {
                value: _vm.fileArr[v],
                disabled: _vm.fileLoading || !(!_vm.disabled && _vm.limit == 1),
                placeholder: "请输入文件地址",
              },
              on: { input: _vm.valueInput },
            }),
            _vm.process > 0 && _vm.process < 100
              ? _c("span", { staticClass: "m-r-10" }, [
                  _vm._v("上传进度:" + _vm._s(_vm.process) + "%"),
                ])
              : _vm._e(),
            _vm.process == 100 && (_vm.disabled || _vm.limit > 1)
              ? _c("img", {
                  staticClass: "file-del",
                  attrs: { src: require("../../../assets/img/fileDel.png") },
                  on: {
                    click: function ($event) {
                      return _vm.hoverDel(v)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }