<template>
    <div class="fl-s-s warp w-1">
        <div class="fr-s-c">
            <div class="choose-img fr-c  relative">
                <el-button type="primary" :loading="fileLoading"><img src="../../../assets/img/uploadFile.png">上传</el-button>
                <input id="choose" ref="choose" :accept="accept" :multiple="limit>1" @change="changeFile" type="file" class="img-view" style="position: absolute;opacity: 0;">
            </div>
			<p class="memo">{{uploadTips}}</p>
        </div>
		
        <div class="w-1 fl-s-s">
            <div class="fr-c w-1 m-t-10 file-item" v-for="(i,v) in fileArr" :key="v" :style="{'background-color':((disabled || limit>1)?'#F5F7FA':'#fff')}">
				<img src="../../../assets/img/fileIcon.png" class="file-icon" v-if="disabled || limit>1"/>
                <el-input class="flex-1" :value="fileArr[v]" @input="valueInput" :disabled="fileLoading || !(!disabled && limit==1)" placeholder="请输入文件地址"></el-input>
                <span class="m-r-10" v-if="process>0 && process<100">上传进度:{{process}}%</span>
                <!-- <el-button type="success" size="mini" v-if="process==100" icon="el-icon-check" circle></el-button> -->
				<img src="../../../assets/img/fileDel.png"  @click="hoverDel(v)" v-if="process==100&&(disabled || limit>1)" class="file-del"/>
            </div>
        </div>
		
		<!-- <div class="w-1 fl-s-s" v-else>
		    <div class="fr-s-c w-1 m-t-10" v-for="(i,v) in fileArr" :key="v">
		        <el-input class="flex-1 m-r-10" :value="fileArr[v]" @input="valueInput" :disabled="fileLoading || !(!disabled && limit==1)" placeholder="请输入文件地址"></el-input>
		        <span v-if="process>0 && process<100">上传进度:{{process}}%</span>
		        <el-button type="danger" size="mini" @click="hoverDel(v)" v-if="process==100" icon="el-icon-delete" circle></el-button>
		    </div>
		</div> -->
    </div>
</template>

<script>
import systemManager from '@/request/systemManager.js'
export default {
    props: {
        fileList: {
            type: [String, Array],
            default: ''
        },
        limit: {
            type: Number,
            default: 9
        },
		uploadTips:{      //  按钮后面的提示
		    type: String,
		    default: ''
		},
        disabled: {
            type: Boolean,
            default: true
        },
        accept:{
            type: String,
            default: '*'
        },
    },
    watch: {
        fileList: function (newval) {
            this.watchFile(newval)
        }
    },
    data () {
        return {
            fileLoading: false,
            fileArr: !this.disabled&&this.limit==1 ? [''] : [],
            process: 0
        };
    },
    mounted () {
        this.watchFile(this.fileList)
    },
    methods: {
        watchFile(newval){
            if (Array.isArray(newval)) {
                this.fileArr = newval;
            } else {
                if (newval) {
                    // console.log(newval,'>>>>>>???????')
                    this.fileArr = [newval];
                } else {
                    this.fileArr = [];
                }
            }
        },
        valueInput (val) {
            this.$emit('update:fileList', Array.isArray(this.fileList) ? [val] : val);
        },
        async changeFile () {
            try {
                if (!this.$refs.choose.files.length) return;
                this.fileLoading = true;
                console.log(this.$refs.choose.files)
                this.fileArr = Array.from(this.$refs.choose.files).map(i => i.name);
                const that = this;
                let { data:result, code, message } = await systemManager.uploadfile(Array.from(this.$refs.choose.files), {
                    onUploadProgress (e) {
                        if (e.lengthComputable) {
                            const point = parseInt((e.loaded / e.total) * 100);
                            that.process = point < 100 ? point : 99;
                            // console.log('上传进度', e.loaded / e.total); //已上传的比例
                        }
                    }
                });
                if (code == 200) {
                    that.process = 100;
                    this.$emit('update:fileList', Array.isArray(this.fileList) ? result.map(i => i.fileUrl) : result[0].fileUrl);
                } else {
                    this.$message.error(message || '上传失败')
                    this.formatData()
                }
            } catch (error) {
                console.log(error)
                this.$message.error('上传失败')
                this.formatData()
            } finally {
                this.fileLoading = false;
                document.getElementById("choose").value = '';
            }

        },
        hoverDel (id) {
            if (this.disabled) {
                this.$emit('update:fileList', Array.isArray(this.fileList) ? this.fileArr.filter((i, v) => v != id) : '');
            } else {
                this.process = 0;
                this.$emit('update:fileList', Array.isArray(this.fileList) ? [''] : '');
            }

        },
        formatData () {
            if (Array.isArray(this.fileList)) {
                this.fileArr = this.fileList;
            } else {
                this.fileArr = [this.fileList]
            }
        }
    },
};
</script>

<style scoped="scoped">
.img-view {
    width: 100%;
    height: 100%;
  /* border-radius: 8px; */
  cursor: pointer;
}
.choose-img {
  background-color: #F5F5F5;
}
.choose-img img {
  width: 12px;
  height: 12px;
  margin-right: 5px;
      margin-top: -3px;
}
.del-content {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 4px;
  cursor: pointer;
}
.del-content img {
  width: 18px;
  height: 18px;
}

.memo{
	font-size: 14px;
	color: #909090;
	padding-left: 20px;
}

.file-icon{
	width: 14px;
	height: 17px;
	    margin-left: 10px;
}

.file-item{
	background-color: #FFFFFF;
	background-image: none;
	border-radius: 4px;
	border: 1px solid #DCDFE6;
}

.file-del{
	width: 14px;
	height: 14px;
	margin-right: 8px;
	opacity: 0;
	cursor: pointer;
}

.file-item:hover .file-del{
	opacity: 1;
}

	

</style>
